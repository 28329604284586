<template>
    <div>
        <v-overlay :value="overlay" absolute>
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>

        <v-data-table :headers="headers" :items="dataTable" :disable-pagination=true :fixed-header=true
            :loading=loadingTable :disable-sort="$vuetify.breakpoint.name == 'xs'" :key="`list-suppliers-${tenantId}`"
            :options.sync="options" hide-default-footer loading-text="Cargando clientes..."
            no-results-text="No se han encontrado clientes" no-data-text="Sin clientes" class="elevation-1" dense>
            <template v-slot:top>
                <ExpandableFilters :filters=filters iconToolbar='mdi-account-star'
                    classButton='py-0 py-md-2 text-center mb-md-0 col-6 col-sm-2 px-md-0' @datafilters=getClientes
                    titleButton="Nuevo" iconButtom="mdi-plus" :actionButton="() => { editCustomer(null) }" />
            </template>
            <template v-slot:item.type="{ item }">
                {{ item.type.split('_').join(' ').toUpperCase() }}
            </template>
            <template v-slot:item.name="{ item }">
                {{ item.name1 + ' ' + (typeof item.name2 != 'undefined' ? item.name2 : '') }}
            </template>
            <template v-slot:footer>
                <v-pagination class='mt-10' v-model=currentPage :length=totalItems @input="handlePageChange"
                    total-visible="15"></v-pagination>
            </template>
            <template v-slot:item.actions="{ item }">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn v-bind="attrs" v-on="on" icon @click="editCustomer(item.party_id)">
                            <v-icon color='orange'>mdi-account-edit</v-icon>
                        </v-btn>
                    </template>
                    <span>Editar</span>
                </v-tooltip>
                <v-tooltip bottom v-if="item.status_id == 'PARTY_IN_PROCESS'">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn v-bind="attrs" v-on="on" icon @click="sendApproval(item)">
                            <v-icon color='success'>mdi-send</v-icon>
                        </v-btn>
                    </template>
                    <span>Enviar para aprobación</span>
                </v-tooltip>

            </template>
        </v-data-table>
        <v-dialog v-model="dialog" persistent fullscreen background-color="white">

            <v-col md="12" class="pt-0 mt-0 mx-0 px-0">
                <v-toolbar color="green" height="35" class="mb-2" flat dark>
                    <v-toolbar-title>{{ customer.name1 }} {{ customer.name2 }} :: {{ customer.party_id }}</v-toolbar-title>
                    <v-divider vertical class="px-2"></v-divider>
                    <v-spacer></v-spacer>
                    <v-btn text small @click="dialog = false"><v-icon>mdi-close</v-icon></v-btn>
                </v-toolbar>
                <v-tabs v-model=tab>
                    <v-tab>
                        DATOS GENERALES
                    </v-tab>
                    <v-tab v-if="existPartyId">
                        DIRECCIONES
                    </v-tab>
                    <v-tab v-if="existPartyId">
                        CONTACTOS
                    </v-tab>
                    <v-tab v-if="existPartyId">
                        ASESORES
                    </v-tab>
                    <v-tab v-if="existPartyId">
                        ACUERDOS COMERCIALES
                    </v-tab>
                    <v-tab v-if="existPartyId">
                        MÉTODOS DE PAGO
                    </v-tab>
                    <v-tab v-if="existPartyId">
                        RECORDATORIOS
                    </v-tab>
                    <v-tab v-if="existPartyId">
                        PAGOS A FAVOR
                    </v-tab>
                </v-tabs>
            </v-col>

            <v-col md="12" class="py-0 my-0">
                <v-tabs-items v-model="tab">
                    <v-tab-item class='mt-6'>
                        <v-card flat class='my-1 mx-2'>
                            <v-row>
                                <v-col cols=12 md=8>
                                    <v-form ref='form_general_data'>
                                        <v-col class='text-center primary white--text py-0'>
                                            DATOS DEL CLIENTE
                                        </v-col>
                                        <v-col cols=12 class="mt-2 subtitle-2">
                                            <v-row>
                                                <v-col cols=12 md=4>
                                                    <v-text-field v-model=searchIdentification dense
                                                        label="Buscar identificación">
                                                        <template v-slot:append>
                                                            <v-tooltip bottom>
                                                                <template v-slot:activator="{ on, attrs }">
                                                                    <v-btn @click="findCustomData"
                                                                        :loading=loadingCustomSearch icon v-bind="attrs"
                                                                        v-on="on">
                                                                        <v-icon>mdi-magnify</v-icon>
                                                                    </v-btn>
                                                                </template>
                                                                <span>Buscar</span>
                                                            </v-tooltip>
                                                        </template>
                                                    </v-text-field>
                                                </v-col>
                                                <v-col cols=12 md=4 style="padding-top: 18px">
                                                    <v-select v-model=customer.party_identification_type_id
                                                        :items=identificationTypes item-text="type" item-value="type"
                                                        label="Tipo de identificación" dense class='mt-2'
                                                        @change="setMinMaxIdentification" />
                                                </v-col>
                                                <v-col cols=12 md=4 style="padding-top: 22px">
                                                    <v-text-field v-model="customer.identification" dense
                                                        label="Identificación" :rules=required :max=maxIdentification
                                                        :min=minIdentification :counter=maxIdentification
                                                        @keyup="identificationType = customer.party_identification_type_id == 'RUC' && customer.identification.charAt(2) == 9 ? 'company' : 'particular'"></v-text-field>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col cols=12 md="6">
                                                    <v-text-field v-model="customer.name1" dense label="Nombre"
                                                        :rules=required></v-text-field>
                                                </v-col>
                                                <v-col cols=12 md=6 v-if="identificationType == 'particular'">
                                                    <v-text-field v-model="customer.name2" dense label="Apellidos"
                                                        :rules=required></v-text-field>
                                                </v-col>
                                                <v-col cols=12 :md="identificationType == 'particular' ? 12 : 6"
                                                    style="padding-top:2px">
                                                    <v-text-field v-model="customer.nombreComercial" dense
                                                        label="Nombre comercial">
                                                        <template v-slot:append>
                                                            <v-tooltip bottom>
                                                                <template v-slot:activator="{ on, attrs }">
                                                                    <v-btn v-bind="attrs" v-on="on"
                                                                        :loading=loadingCustomStore icon>
                                                                        <v-icon @click="storeCustomer" icon
                                                                            color=success>mdi-floppy
                                                                        </v-icon>
                                                                    </v-btn>
                                                                </template>
                                                                <span>Guardar</span>
                                                            </v-tooltip>
                                                        </template>
                                                    </v-text-field>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-form>
                                </v-col>
                                <v-col cols=12 md=4>
                                    <v-col class='text-center primary white--text py-0'>
                                        CLASIFICACIONES
                                    </v-col>
                                    <div class="mt-2" v-if=customer.classifications.length>
                                        <div v-for="(data, index) in customer.classifications" :key=index>
                                            <div class='text-caption' v-if="data.party_classification_group_id != ''">
                                                {{ index + 1 }}) {{ data.description }}
                                                <v-icon @click="deleteClasification(customer.classifications[index])"
                                                    color="red">mdi-delete-forever
                                                </v-icon>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-else class='mt-2'>
                                        <v-alert border="right" colored-border type="error" elevation="2" dense>
                                            Sin clasificaciones registradas
                                        </v-alert>
                                    </div>
                                    <div class="mt-4">
                                        <v-autocomplete v-model=customer.newClassifications :items=classificationsGroups
                                            :loading=loadingBtn item-text="description"
                                            item-value="party_classification_group_id" label="Otras clasificaciones" dense
                                            multiple class='mt-2'>
                                            <template v-slot:append-outer>
                                                <v-tooltip bottom>
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-icon v-bind="attrs" v-on="on" color="success"
                                                            @click="storeNewClassification">
                                                            mdi-floppy
                                                        </v-icon>
                                                    </template>
                                                    <span>Guardar</span>
                                                </v-tooltip>
                                            </template>
                                        </v-autocomplete>
                                    </div>
                                </v-col>
                            </v-row>
                            <v-col class='text-center'>
                                <v-btn class="text-center" color='default' @click=closeDialog>
                                    <v-icon>mdi-cancel</v-icon> Cerrar
                                </v-btn>
                            </v-col>
                        </v-card>
                    </v-tab-item>
                    <v-tab-item class='mt-6'>
                        <v-card flat class='my-2 mx-2'>
                            <v-row>
                                <v-col sm=5 cols="12">
                                    <v-form ref='from_direction'>
                                        <v-col class='py-0'>
                                            <v-autocomplete v-model='province' :items=provinces item-value='geo_id'
                                                item-text='geo_name' dense label='Seleccione la provincia'
                                                prepend-inner-icon='mdi-map' :rules=required
                                                @change="getGeoData(`${province}/parroquias-provincia`, 'states')"></v-autocomplete>
                                        </v-col>
                                        <v-col class='py-0'>
                                            <v-autocomplete v-model=state :items=states item-value='parroquia_id'
                                                item-text='parroquia' dense label='Seleccione la ciudad'
                                                prepend-inner-icon='mdi-city' return-object :rules=required
                                                @change="getGeoData(`${state.cantonId}/sectores-canton`, 'sector')"></v-autocomplete>
                                        </v-col>
                                        <v-col class='py-0'>
                                            <v-autocomplete v-if=autoCompleteSector v-model=sector :items=sectors
                                                item-value='id' item-text='nombre' dense label='Seleccione el sector'
                                                prepend-inner-icon='mdi-city' :rules=required></v-autocomplete>
                                            <v-text-field v-else v-model=sector dense label='Escriba el sector'
                                                prepend-inner-icon='mdi-city' :rules=required></v-text-field>
                                        </v-col>
                                        <v-col class='py-0'>
                                            <v-text-field v-model=street dense label='Calle principal y número'
                                                prepend-inner-icon='mdi-map-marker-radius' :rules=required></v-text-field>
                                        </v-col>
                                        <v-col class='py-0'>
                                            <v-text-field v-model=street2 dense label='Calle secundaria'
                                                prepend-inner-icon='mdi-map-marker-radius' :rules=required></v-text-field>
                                        </v-col>
                                        <v-col class='pb-0'>
                                            <v-textarea v-model=reference outlined label='Referencias'></v-textarea>
                                        </v-col>
                                        <v-col class='py-0'>
                                            <v-select v-model='directionPurposes' :items=contactMechAddressPurposeType
                                                item-text="description" item-value="contact_mech_purpose_type_id" outlined
                                                dense multiple label="Proposios" :rules=required></v-select>
                                        </v-col>
                                        <v-col class='text-center'>
                                            <v-btn class="text-center" color='primary' @click="storeDirection">
                                                <v-icon>mdi-content-save</v-icon> Guardar
                                            </v-btn>
                                            <v-btn class="text-center" color='default' @click=closeDialog>
                                                <v-icon>mdi-cancel</v-icon> Cerrar
                                            </v-btn>
                                        </v-col>
                                    </v-form>
                                </v-col>
                                <v-col sm=1 class='text-center py-0'>
                                    <v-divider class='col-sm-1 px-0 mx-0' inset vertical style="height:100%"></v-divider>
                                </v-col>
                                <v-col sm=6 cols="12">
                                    <div v-if='customer.directions.length'>
                                        <div v-for="(direction, index) in customer.directions" :key=index>
                                            <div><b>{{ index + 1 }}) Dirección:</b></div>
                                            <div class='text-caption'>
                                                <div style='background:white; border:1px solid #9e9e9e; border-radius:5px'>
                                                    <div class="row">
                                                        <div class='col-11'>
                                                            <div class=' ml-2'>
                                                                {{ typeof direction.address1 != 'undefined' ?
                                                                    direction.address1 : '' }} {{ typeof direction.address2
        !=
        'undefined' ? direction.address2 + ',' : '' }} {{ typeof
        direction.city != 'undefined' ? direction.city + ',' :
        '' }}
                                                                {{ direction.sector }} {{
                                                                    direction.state_province_geo_id
                                                                }},
                                                                {{ direction.observaciones }}
                                                            </div>
                                                        </div>
                                                        <div class="col-1 text-center"
                                                            style='display: flex; align-items: center; position: relative;right: 17px;'>
                                                            <v-icon
                                                                @click='deleteDirection(customer.directions[index])'>mdi-delete-forever</v-icon>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div><b>Propositos:</b></div>
                                            <div v-if="direction.party_contact_mech_purposes.length > 0">
                                                <div v-for="(purpose, index) in direction.party_contact_mech_purposes"
                                                    :key=index>
                                                    <div class='text-caption ml-2'>
                                                        {{ index + 1 }}) {{ purpose.description }}
                                                        <v-icon
                                                            @click='deleteDirectionPuporse(direction, purpose)'>mdi-delete-forever</v-icon>
                                                    </div>
                                                </div>
                                            </div>
                                            <v-select v-model='customer.directions[index].other_purposes'
                                                :items=contactMechAddressPurposeType item-text="description"
                                                item-value="contact_mech_purpose_type_id" outlined dense multiple
                                                label="Otros proposios" append-outer-icon="mdi-content-save"
                                                @click:append-outer='storeOtherDirectionPursope(index)'
                                                class='mt-2'></v-select>
                                        </div>
                                    </div>
                                    <div v-else class='mt-2'>
                                        <v-alert border="right" colored-border type="error" elevation="2" dense>
                                            Sin direcciones registradas
                                        </v-alert>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-tab-item>
                    <v-tab-item class='mt-6' v-if="existPartyId">
                        <v-card flat class='my-2 mx-2'>
                            <v-form ref='form_contacts_data'>
                                <v-row>
                                    <v-col>
                                        <v-col class='text-center primary white--text py-0'>
                                            CONTACTOS
                                            <v-btn class='mb-1 grey lighten-3' x-small style="right: -2.5rem;top: 2px;"
                                                @click="addContacts">
                                                <v-icon>mdi-plus</v-icon>
                                            </v-btn>
                                        </v-col>
                                        <v-col v-if="customer.contacts.length">
                                            <v-row v-for="(contact, index) in customer.contacts" :key=index>
                                                <v-col cols=12 sm=6 md=2>
                                                    <v-text-field v-model="contact.first_name" dense label="Nombres"
                                                        :rules=required></v-text-field>
                                                </v-col>
                                                <v-col cols=12 sm=6 md=2>
                                                    <v-text-field v-model="contact.last_name" dense label="Apellidos"
                                                        :rules=required></v-text-field>
                                                </v-col>
                                                <v-col cols=12 sm=6 md=2>
                                                    <v-text-field v-model="contact.number" v-mask="'593-##-#######'" dense
                                                        label="Teléfono" :rules=required></v-text-field>
                                                </v-col>
                                                <v-col cols=12 sm=6 md=3>
                                                    <v-text-field v-model="contact.info_string" dense
                                                        label="Correo"></v-text-field>
                                                </v-col>
                                                <v-col cols=11 sm=5 md=2>
                                                    <v-text-field v-model="contact.position_title" dense
                                                        label="Cargo"></v-text-field>
                                                </v-col>
                                                <v-col cols=1>
                                                    <v-btn icon color='red' class='mt-2'
                                                        @click=deleteContact(customer.contacts[index])>
                                                        <v-icon>mdi-delete-forever</v-icon>
                                                    </v-btn>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col v-else>
                                            <v-alert border="right" colored-border type="error" elevation="2" dense>
                                                Sin contactos registrados
                                            </v-alert>
                                        </v-col>
                                    </v-col>
                                </v-row>
                                <v-col class='text-center'>
                                    <v-btn class="text-center" color='primary' @click="storeContacts" :loading=loadingBtn>
                                        <v-icon>mdi-content-save</v-icon> Guardar
                                    </v-btn>
                                    <v-btn class="text-center" color='default' @click=closeDialog>
                                        <v-icon>mdi-cancel</v-icon> Cerrar
                                    </v-btn>
                                </v-col>
                            </v-form>
                        </v-card>
                    </v-tab-item>
                    <v-tab-item class='mt-6' v-if="existPartyId">
                        <v-card flat class='my-2 mx-2'>
                            <v-form ref='form_advisers_data'>
                                <v-col>
                                    <v-col class='text-center primary white--text py-0'>
                                        ASIGNAR ASESORES
                                        <v-btn class='mb-1 grey lighten-3' x-small style="right: -2.5rem;top: 2px;"
                                            @click="addAdviser">
                                            <v-icon>mdi-plus</v-icon>
                                        </v-btn>
                                    </v-col>
                                    <v-col v-if="customer.advisers.length">
                                        <v-row>
                                            <v-col v-for="(adviser, index) in customer.advisers" :key=index cols=12 sm=6
                                                md=4>
                                                <v-autocomplete v-model=adviser.party_id :items=advisers :loading=loadingBtn
                                                    item-text="name" item-value="party_id" label="Seleccione un asesor"
                                                    dense class='mt-2'>
                                                    <template v-slot:append-outer>
                                                        <div>
                                                            <v-tooltip bottom v-if="adviser.new">
                                                                <template v-slot:activator="{ on, attrs }">
                                                                    <v-icon v-bind="attrs" v-on="on" color="success"
                                                                        @click="storeAdviser(adviser)">
                                                                        mdi-content-save
                                                                    </v-icon>
                                                                </template>
                                                                <span>Guardar</span>
                                                            </v-tooltip>
                                                        </div>
                                                        <div>
                                                            <v-tooltip bottom>
                                                                <template v-slot:activator="{ on, attrs }">
                                                                    <v-icon v-bind="attrs" v-on="on" class="ml-2"
                                                                        color="red"
                                                                        @click="deleteAdviser(adviser)">mdi-delete-forever</v-icon>
                                                                </template>
                                                                <span>Eliminar</span>
                                                            </v-tooltip>
                                                        </div>
                                                    </template>
                                                </v-autocomplete>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                    <v-col v-else>
                                        <v-alert border="right" colored-border type="error" elevation="2" dense>
                                            Sin asesores asignados
                                        </v-alert>
                                    </v-col>
                                </v-col>
                                <v-col class='text-center'>
                                    <v-btn class="text-center" color='default' @click=closeDialog>
                                        <v-icon>mdi-cancel</v-icon> Cerrar
                                    </v-btn>
                                </v-col>
                            </v-form>
                        </v-card>
                    </v-tab-item>
                    <v-tab-item class='mt-6' v-if="existPartyId">
                        <v-card flat class='my-2 mx-2'>
                            <v-row>
                                <v-col cols=8>
                                    <v-col>
                                        <v-col class='text-center primary white--text py-0'>
                                            PRODUCTOS
                                        </v-col>
                                        <div class="mt-4">
                                            <v-autocomplete v-model=agreementProduct :items=agreementProducts
                                                :search-input.sync="syncAgreementProduct" item-text="internal_name"
                                                return-object dense label="Buscar producto" cache-items class="mx-4" flat
                                                clearable hide-no-data hide-details multiple>
                                                <template v-slot:append>
                                                    <v-tooltip bottom>
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-btn icon v-bind="attrs" v-on="on" color="success"
                                                                @click="searchAgreementProduct"
                                                                :loading=loadingAgreementSearch>
                                                                <v-icon>mdi-magnify</v-icon>
                                                            </v-btn>
                                                        </template>
                                                        <span>Buscar</span>
                                                    </v-tooltip>
                                                </template>
                                                <template v-slot:append-outer>
                                                    <v-btn color="success" class="mx-2" @click="addAgreementProduct" small
                                                        v-if="btnAddAgreementProduct"> Agregar producto </v-btn>
                                                    <v-btn v-if="customer.crear_acuerdo" color="success" small
                                                        @click="dialogNewAgreement = !dialogNewAgreement"> Crear acuerdo
                                                    </v-btn>
                                                </template>
                                            </v-autocomplete>
                                        </div>
                                        <div>
                                            <v-data-table :headers="agreementHeaders" :items="customer.agreements"
                                                :single-expand="true" :expanded.sync="expanded" item-key="agreement_id"
                                                show-expand class="elevation-1">
                                                <template v-slot:expanded-item="{ headers, item }">
                                                    <td :colspan="headers.length">
                                                        <div class="my-3">
                                                            <v-simple-table dense>
                                                                <template v-slot:default>
                                                                    <thead>
                                                                        <tr>
                                                                            <th class="text-left">
                                                                                Prodcuto
                                                                            </th>
                                                                            <th class="text-center">
                                                                                Precio normal
                                                                            </th>
                                                                            <th class="text-center">
                                                                                Precio acuerdo
                                                                            </th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr v-for="product in item.products"
                                                                            :key="product.product_id">
                                                                            <td style="width:390px">{{
                                                                                product.internal_name.substring(0, 50) +
                                                                                '...'
                                                                            }}</td>
                                                                            <td class="text-center" style="width:110px">
                                                                                ${{ product.pvp }}
                                                                            </td>
                                                                            <td class="text-center">
                                                                                <v-text-field type='number'
                                                                                    prepend-icon='mdi-currency-usd'
                                                                                    hide-details dense :rules=required
                                                                                    v-model="product.price">
                                                                                    <template v-slot:append-outer>
                                                                                        <v-btn color='success' icon
                                                                                            :loading=loadingProductAgreementStore
                                                                                            @click="storeProductAgreement(product)">
                                                                                            <v-icon
                                                                                                color='success'>mdi-floppy</v-icon>
                                                                                        </v-btn>
                                                                                        <v-btn color='success' icon
                                                                                            :loading=loadingProductAgreementDelete
                                                                                            @click="deleteProductAgreement(product)">
                                                                                            <v-icon
                                                                                                color='red'>mdi-delete-forever</v-icon>
                                                                                        </v-btn>
                                                                                    </template>
                                                                                </v-text-field>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </template>
                                                            </v-simple-table>
                                                        </div>
                                                    </td>
                                                </template>
                                                <template v-slot:item.action="{ item }">
                                                    <v-icon color="warning" @click="editAgreement(item)">mdi-pencil</v-icon>
                                                </template>
                                            </v-data-table>
                                        </div>
                                    </v-col>
                                </v-col>
                                <v-col cols=4>
                                    <v-col>
                                        <v-col class='text-center primary white--text py-0'>
                                            LÍNEA DE CRÉDITO
                                        </v-col>
                                    </v-col>
                                    <v-form ref='form_credit_data'>
                                        <v-col cols="12">
                                            <v-text-field v-model="customer.creditAmount" dense type="number"
                                                label="Monto del crédito" :rules=requiredMin></v-text-field>
                                        </v-col>
                                        <v-col cols="12">
                                            <v-text-field v-model="customer.daysTerm" dense type="number"
                                                label="Días de plazo" :rules=requiredMin></v-text-field>
                                        </v-col>

                                        <v-col cols="12">
                                            <v-text-field v-model=estadoLc dense readonly=true type="string"
                                                label="Estado"></v-text-field>
                                        </v-col>

                                        <v-col cols="12" class="text-center">
                                            <v-btn v-if="successWorkrEffort" color="success" @click="approveCreditData"
                                                :loading=loadingStoreCreditData>
                                                <v-icon>mdi-check</v-icon> Aprobar
                                            </v-btn>
                                            <v-btn v-if="customer.aprobado == true || customer.aprobado == null"
                                                color="success" @click="storeCreditData" :loading=loadingStoreCreditData>
                                                <v-icon>mdi-floppy</v-icon> Guardar
                                            </v-btn>
                                        </v-col>
                                    </v-form>
                                </v-col>
                                <v-col class='text-center'>
                                    <v-btn class="text-center" color='default' @click=closeDialog>
                                        <v-icon>mdi-cancel</v-icon> Cerrar
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-tab-item>
                    <v-tab-item class='mt-6' v-if="existPartyId">
                        <v-card flat class='my-2 mx-2'>
                            <v-row>
                                <v-col cols=12>
                                    <v-col>
                                        <v-col class='text-center primary white--text py-0'>
                                            MÉTODOS DE PAGO DEL CLIENTE
                                        </v-col>
                                        <v-data-table :headers="paymentsMethodsHeaders" :items="paymentMethods"
                                            item-key="payment_id" class="elevation-1">
                                            <template v-slot:item.action="{ item }">
                                                <v-tooltip bottom>
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-btn v-bind="attrs" v-on="on" icon
                                                            @click="deletePaymentMethod(item)">
                                                            <v-icon color="red"> mdi-delete-forever</v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span>Eliminar</span>
                                                </v-tooltip>
                                            </template>

                                        </v-data-table>
                                        <v-dialog v-model="dialogCreditCard" persistent max-width="800">
                                            <v-card>
                                                <v-alert dense type="success" icon="mdi-credit-card-plus">NUEVA TARJETA
                                                    DE
                                                    CRÉDITO</v-alert>
                                                <v-col col=12>
                                                    <v-form ref="form_data_credit_card">
                                                        <v-row>
                                                            <v-col cols=12 md=6 class="mt-2">
                                                                <v-select v-model="dataStoreCreditCard.enum_id"
                                                                    :items=enumCreditCards item-value="enum_id"
                                                                    item-text="description" label='Tipo de tarjeta'
                                                                    class="mt-0 input-quotation" style="height:25px" dense
                                                                    :rules="required" />
                                                            </v-col>
                                                            <v-col cols=12 md=6>
                                                                <v-text-field v-model=dataStoreCreditCard.first_name_on_card
                                                                    dense label='Nombre en tarjeta' :rules="required"
                                                                    autofocus></v-text-field>
                                                            </v-col>
                                                            <v-col cols=12 md=6>
                                                                <v-text-field v-model=dataStoreCreditCard.last_name_on_card
                                                                    dense label='Apellido en tarjeta'
                                                                    :rules="required"></v-text-field>
                                                            </v-col>
                                                            <v-col cols=12 md=6>
                                                                <v-text-field v-model=dataStoreCreditCard.card_number
                                                                    type="number" dense label='Número de tarjeta'
                                                                    :rules="required"></v-text-field>
                                                            </v-col>
                                                            <v-col cols=12 md=3>
                                                                <v-text-field v-model=dataStoreCreditCard.expire_date
                                                                    type="month" dense label='Fecha expiración'
                                                                    :rules="required"></v-text-field>
                                                            </v-col>
                                                            <v-col cols=12 md=3>
                                                                <v-text-field v-model=dataStoreCreditCard.cvv v-mask="'###'"
                                                                    dense label='Código reverso'></v-text-field>
                                                            </v-col>
                                                        </v-row>
                                                    </v-form>
                                                </v-col>
                                                <v-col col=12 class='text-center'>
                                                    <v-btn small color="success" @click="storeCreditCard">
                                                        <v-icon>mdi-floppy</v-icon> Guardar
                                                    </v-btn>
                                                    <v-btn small color="secondary" @click="closeDialogCreditCard">
                                                        <v-icon>mdi-cancel</v-icon> Cerrar
                                                    </v-btn>
                                                </v-col>
                                            </v-card>
                                        </v-dialog>
                                    </v-col>
                                </v-col>
                                <v-col class='text-center'>
                                    <v-btn class="text-center" color='default' @click=closeDialog>
                                        <v-icon>mdi-cancel</v-icon> Cerrar
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-tab-item>
                    <v-tab-item class='mt-6' v-if="existPartyId">
                        <v-card flat class='my-2 mx-2'>
                            <v-row>
                                <v-col cols=12>
                                    <v-col>
                                        <v-col class='text-center primary white--text py-0'>
                                            RECORDATORIOS
                                        </v-col>
                                        <v-data-table :headers="reminderHeaders" :items="customer.reminders"
                                            item-key="work_effort_id" class="elevation-1">
                                            <template v-slot:item.action="{ item }">
                                                <v-tooltip bottom>
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-btn v-bind="attrs" v-on="on" icon @click="editReminder(item)">
                                                            <v-icon color="blue">mdi-eye</v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span>Ver recodatorio</span>
                                                </v-tooltip>
                                            </template>
                                        </v-data-table>
                                    </v-col>
                                </v-col>
                                <v-col class='text-center'>
                                    <v-btn class="text-center" color='default' @click=closeDialog>
                                        <v-icon>mdi-cancel</v-icon> Cerrar
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-tab-item>
                    <v-tab-item class='mt-6' v-if="existPartyId">
                        <v-card flat class='my-2 mx-2'>
                            <v-row>
                                <v-col cols=12>
                                    <v-col>
                                        <v-col class='text-center primary white--text py-0'>
                                            PAGOS A FAVOR DEL CLIENTE
                                        </v-col>
                                        <v-data-table :headers="paymentsInFavorHeaders" :items="customer.paymentsInFavor"
                                            item-key="payment_id" class="elevation-1" />
                                    </v-col>
                                </v-col>
                                <v-col class='text-center'>
                                    <v-btn class="text-center" color='default' @click=closeDialog>
                                        <v-icon>mdi-cancel</v-icon> Cerrar
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-tab-item>
                </v-tabs-items>
            </v-col>

        </v-dialog>
        <v-dialog v-model="dialogNewAgreement" max-width="600" persistent background-color="white">
            <v-col>
                <v-alert dense type="info" icon="mdi-thumb-up-outline">
                    Acuerdo
                </v-alert>
                <v-form ref='form_agreement_data'>
                    <v-row>
                        <v-col cols=12 md=6>
                            <v-menu v-model="menu1" :close-on-content-click="false" :nudge-right="40"
                                transition="scale-transition" offset-y min-width="auto">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field v-model="expeditionAgreement" label="Desde" prepend-icon="mdi-calendar"
                                        readonly dense v-bind="attrs" v-on="on" :rules="requiredMinDay"></v-text-field>
                                </template>
                                <v-date-picker no-title v-model="expeditionAgreement"
                                    @input="menu1 = false"></v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col cols=12 md=6>
                            <v-menu v-model="menu2" :close-on-content-click="false" :nudge-right="40"
                                transition="scale-transition" offset-y min-width="auto">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field v-model="expirationAgreement" label="Hasta" dense
                                        prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"
                                        :rules="requiredMaxDay"></v-text-field>
                                </template>
                                <v-date-picker no-title v-model="expirationAgreement"
                                    @input="menu2 = false"></v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col cols=12>
                            <v-textarea v-model=agreementDescription outlined label='Descripción'></v-textarea>
                        </v-col>
                        <v-col cols=12 class="text-center">
                            <v-btn color="success" @click="storeNewAgreement">
                                <v-icon>mdi-flopppy</v-icon> Guardar
                            </v-btn>
                            <v-btn color='default' @click=closeDialogNewAgreement>
                                <v-icon>mdi-cancel</v-icon> Cerrar
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-form>
            </v-col>
        </v-dialog>
        <v-dialog v-model="dialogReminder" max-width="1000" persistent background-color="white">
            <v-col>
                <v-alert dense type="info" icon="mdi-comment-processing-outline">
                    RECORDATORIO
                </v-alert>
                <v-form ref='form_reminder'>
                    <v-row>
                        <v-col cols=12 md=6>
                            <v-text-field label="Asunto" prepend-icon='mdi-comment-check-outline' hide-details dense
                                :rules=required v-model="reminder.work_effort_name" />
                        </v-col>
                        <v-col cols=12 md=6 class="pt-2">
                            <v-autocomplete v-model="reminder.work_effort_purpose_type_id" :items=purposes
                                item-text="description" item-value="work_effort_purpose_type_id" label="Proposito"
                                prepend-icon='mdi-comment-question-outline' dense class='mt-2' :rules=required />
                        </v-col>
                        <v-col cols=12 md=4>
                            <v-text-field v-model="reminder.estimated_start_date" type="datetime-local"
                                label="Fecha y hora inicio" prepend-icon="mdi-calendar" dense
                                :rules=required></v-text-field>
                        </v-col>
                        <v-col cols=12 md=4>
                            <v-autocomplete v-model="reminder.motivo_id" :items=reasons label="Resultado"
                                prepend-icon='mdi-comment-alert-outline' dense class='mt-2' item-text="name"
                                item-value="motivo_id" :rules=required />
                        </v-col>
                        <v-col cols=12 md=4>
                            <v-autocomplete v-model="reminder.contact_mech_id" :items=customer.phones label="Teléfonos"
                                prepend-icon='mdi-cellphone-android' dense class='mt-2' item-text="contact_number"
                                item-value="contact_mech_id" :rules=required>
                                <template v-slot:item="{ item }">
                                    <v-list-item-content>
                                        <v-list-item-title>{{ item.contact_number }}</v-list-item-title>
                                    </v-list-item-content>
                                    <v-list-item-action>
                                        <v-btn icon @click="deletePhone($event, item)">
                                            <v-icon color="red">mdi-delete-forever</v-icon>
                                        </v-btn>
                                    </v-list-item-action>
                                </template>
                                <template v-slot:selection="{ item }">
                                    {{ item.contact_number }}
                                </template>
                                <template v-slot:append-outer>
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn v-bind="attrs" v-on="on" icon @click="dialogPhoneClient = true"
                                                style="position:relative;bottom:5px">
                                                <v-icon color="success">mdi-phone-plus</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Crear nuevo teléfono</span>
                                    </v-tooltip>
                                </template>
                            </v-autocomplete>
                        </v-col>
                        <v-col cols=12>
                            <v-textarea v-model=reminder.description outlined label='Descripción'
                                :rules=required></v-textarea>
                        </v-col>
                        <v-col cols=12 class="text-center">
                            <v-btn color="success" @click="storeReminder"
                                v-if="reminder.current_status_id == 'CAL_TAR_PLANIFICADO'">
                                <v-icon>mdi-flopppy</v-icon> Guardar
                            </v-btn>
                            <v-btn color='default' @click="closeDialogReminder">
                                <v-icon>mdi-cancel</v-icon> Cerrar
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-form>
            </v-col>
        </v-dialog>
        <v-dialog v-model="dialogPhoneClient" persistent max-width="600">
            <v-card>
                <CapturePhone v-if=dialogPhoneClient @storePhone=storePhone @close="dialogPhoneClient = false" />
            </v-card>
        </v-dialog>
    </div>
</template>

<script>

import { mapState, mapMutations, mapActions, mapGetters } from 'vuex'
import Vue from 'vue'
import ExpandableFilters from '../general/ExpandableFilters'
import PartyComponent from '../general/PartyComponent'
import CapturePhone from '../general/CapturePhone'
import moment from 'moment'

export default {
    name: 'ClientesComponent',
    components: {
        ExpandableFilters,
        PartyComponent,
        CapturePhone
    },
    data: () => ({
        dataTable: [],
        setfilters: [],
        expanded: [],
        agreementProducts: [],
        btnAddAgreementProduct: false,
        loadingProductAgreementStore: false,
        loadingProductAgreementDelete: false,
        loadingStoreCreditData: false,
        dialogNewAgreement: false,
        agreementId: null,
        dialog: false,
        overlay: false,
        menu1: false,
        menu2: false,
        syncAgreementProduct: null,
        loadingCustomSearch: false,
        loadingCustomStore: false,
        editCurrentAgreement: false,
        loadingAgreementSearch: false,
        searchIdentification: null,
        agreementDescription: null,
        agreementProduct: [],
        maxIdentification: 10,
        minIdentification: 10,
        expeditionAgreement: null,
        expirationAgreement: null,
        successWorkrEffort: false,
        dialogCreditCard: false,
        dialogReminder: false,
        dialogPhoneClient: false,
        province: null,
        street: null,
        street2: null,
        direction: null,
        province: null,
        state: null,
        sector: null,
        reference: null,
        autoCompleteSector: false,
        tab: null,
        search: '',
        totalItems: 1,
        currentPage: 1,
        itemsPerPage: 20,
        existPartyId: false,
        dataParty: {},
        availableItems: {},
        options: {},
        reminder: {},
        dataStoreCreditCard: {
            enum_id: null,
            first_name_on_card: null,
            last_name_on_card: null,
            card_number: null,
            expire_date: null,
            cvv: null,
        },
        defaultDataStoreCreditCard: {
            card_number: null,
            expire_date: null,
            cvv: null,
            first_name_on_card: null,
            last_name_on_card: null,
            enum_id: null
        },
        reasons: [],
        classificationsGroups: [],
        assignedClassifications: [],
        enumCreditCards: [],
        advisers: [],
        retentionsIva: [],
        retentionsRent: [],
        deadlines: [],
        companyTypes: [],
        paymentMethods: [],
        purposes: [],
        contactMechAddressPurposeType: [],
        provinces: [],
        sectors: [],
        states: [],
        directionPurposes: [],
        partyIdCustomer: null,
        customer: {
            name1: '',
            name2: '',
            nombreComercial: '',
            identification: '',
            crear_acuerdo: false,
            party_id: null,
            creditAmount: null,
            daysTerm: null,
            aprobado: null,
            party_identification_type_id: 'CEDULA',
            newClassifications: [],
            classifications: [],
            contacts: [],
            advisers: [],
            agreement: [],
            reminders: [],
            phones: [],
            directions: [
                {
                    state_province_geo_id: '',
                    state: '',
                    sector: '',
                    street: '',
                    street2: '',
                    reference: '',
                    contact_mech_id: '',
                    party_contact_mech_purposes: [
                        {
                            contact_mech_purpose_type_id: '',
                            description: ''
                        }
                    ]
                }
            ]
        },
        defaultCustomer: {
            name1: null,
            name2: null,
            nombreComercial: null,
            identification: null,
            party_id: null,
            creditAmount: null,
            daysTerm: null,
            crear_acuerdo: false,
            party_identification_type_id: 'CEDULA',
            newClassifications: [],
            classifications: [],
            contacts: [],
            advisers: [],
            agreement: [],
            reminders: [],
            phones: [],
            directions: [
                {
                    state_province_geo_id: '',
                    state: '',
                    sector: '',
                    street: '',
                    street2: '',
                    reference: '',
                    contact_mech_id: '',
                    party_contact_mech_purposes: [
                        {
                            contact_mech_purpose_type_id: '',
                            description: ''
                        }
                    ]
                }
            ]
        },
        headers: [
            { text: 'Código', align: 'start', value: 'party_id' },
            { text: 'Nombre', align: 'start', value: 'name' },
            { text: 'Identificación', align: 'start', value: 'identification' },
            { text: 'Estado', align: 'start', value: 'status' },
            { text: 'Tipo', align: 'start', value: 'type' },
            { text: 'Actions', align: 'start', value: 'actions', align: 'center' }
        ],
        agreementHeaders: [
            { text: 'Acuerdo', align: 'start', value: 'agreement_id' },
            { text: 'Cliente', align: 'start', value: 'customer' },
            { text: 'Tipo', align: 'start', value: 'description' },
            { text: 'Desde', align: 'start', value: 'from_date' },
            { text: 'Hasta', align: 'start', value: 'thru_date' },
            { text: 'Productos', align: 'center', value: 'data-table-expand' },
            { text: 'Editar', align: 'start', value: 'action' }
        ],
        paymentsInFavorHeaders: [
            { text: 'ID', align: 'start', value: 'payment_id' },
            { text: 'Forma de pago', align: 'start', value: 'description' },
            { text: 'Monto', align: 'start', value: 'amount' },
            { text: 'Saldo', align: 'start', value: 'balance' },
            { text: 'Referncia', align: 'start', value: 'payment_ref_num' },
            { text: 'Estado', align: 'start', value: 'status' }
        ],
        paymentsMethodsHeaders: [
            { text: 'ID', align: 'start', value: 'payment_method_id' },
            { text: 'Método de pago', align: 'start', value: 'description' },
            { text: 'Tipo', align: 'start', value: 'card_type' },
            { text: 'Número', align: 'start', value: 'card_number' },
            { text: 'Fecha de expiración', align: 'start', value: 'expire_date' },
            { text: 'Acciones', align: 'start', value: 'action' }
        ],
        reminderHeaders: [
            { text: 'ID', align: 'start', value: 'work_effort_id' },
            { text: 'Propósito', align: 'start', value: 'proposito' },
            { text: 'Asunto', align: 'start', value: 'work_effort_name' },
            { text: 'Fecha de inicio	', align: 'start', value: 'estimated_start_date' },
            { text: 'Fecha final', align: 'start', value: 'estimated_completion_date' },
            { text: 'Estado', align: 'center', value: 'estado' },
            { text: 'Acciones', align: 'start', value: 'action' }
        ],
        filters: [
            {
                cols: 6,
                class: 'py-0 py-md-2 col-md-1 pl-0 pl-sm-2',
                v_model: '',
                label: 'Código',
                type: 'input_text'
            },
            {
                cols: 6,
                class: 'py-0 py-md-2 col-md-2 pr-0 pr-sm-2',
                v_model: '',
                label: 'Identificación',
                type: 'input_text'
            },
            {
                cols: 6,
                class: 'py-0 py-md-2 col-md-2 pl-0 pl-sm-2',
                v_model: '',
                label: 'Nombres',
                type: 'input_text'
            },
            {
                cols: 6,
                class: 'py-0 py-md-2 col-md-2 pl-0 pl-sm-2',
                v_model: '',
                label: 'Apellidos',
                type: 'input_text'
            },
            {
                cols: 6,
                class: 'py-0 py-md-2 col-md-1 pl-0 pl-sm-2',
                v_model: '',
                label: 'Estado',
                type: 'input_select',
                text: 'name',
                value: 'id',
                items: [
                    { id: 'PARTY_ENABLED', name: 'Habilitado' },
                    { id: 'PARTY_DISABLED', name: 'Deshabilitado' }
                ]
            },
            {
                cols: 6,
                class: 'py-0 py-md-2 col-md-2 pl-0 pl-sm-2',
                v_model: '',
                label: 'Clasificación',
                type: 'input_select',
                text: 'description',
                value: 'party_classification_group_id',
                items: [],
                clearable: true
            }
        ],
        identificationType: 'particular',
        required: [v => !!v || 'El campo es requerido'],
        identificationTypes: [
            { type: 'CEDULA' },
            { type: 'RUC' },
            { type: 'PASAPORTE' }
        ],
        requiredMin: [
            v => !!v || 'El campo es requerido',
            v => (!!v && v > 0) || 'Debe escribir un número mayor a cero'
        ],
        emailValid: [
            v => !!v || 'Debe escribir un correo electrónico',
            v => /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || 'Debe escribir un correo válido'
        ],
        minVal: [
            v => v >= 0 || 'El valor debe ser 0 o mayor',
            v => !!v || 'El campo es requerido'
        ],
    }),
    watch: {
        options: {
            handler() {
                this.getClientes(this.filters)
            },
            deep: true
        },
        syncAgreementProduct: function (val) {
            val && val !== this.agreementProduct && this.searchAgreementProduct(val)
        },
        expanded: function (val) {
            this.btnAddAgreementProduct = val.length && this.agreementProduct.length
        },
        agreementProduct: function (val) {
            this.btnAddAgreementProduct = val.length && this.expanded.length
        }
    },
    computed: {

        ...mapState('pos', ['menuPos', 'loadingBtn', 'paramAlertQuestion', 'partyIdClient']),

        ...mapState('master', ['loadingTable', 'tenantId', 'user']),

        url() {
            return this.$route.fullPath.split('/')[1]
        },

        estadoLc() {

            if (this.customer.aprobado == true || this.customer.aprobado == "true") {
                return "Aprobado"
            } else if (this.customer.aprobado == false || this.customer.aprobado == "false") {
                return "Por Aprobar"
            } else {
                return this.customer.aprobado
            }


        },

        requiredMinDay() {
            return [
                (v) => {
                    if (!v || (v < moment().format('YYYY-MM-DD') && !this.editCurrentAgreement)) {
                        return 'La fecha debe ser mayor o igual a hoy'
                    } else {
                        return true
                    }
                }
            ]
        },

        requiredMaxDay() {
            return [
                (v) => {
                    if (!v || v <= this.expeditionAgreement) {
                        return 'La fecha debe ser mayor al inicio'
                    } else {
                        return true
                    }
                }
            ]
        }

    },
    methods: {

        ...mapMutations('master', ['setMenu', 'setUrl', 'setLoadingTable', 'setTitleToolbar', 'setLoadingBtn']),

        ...mapActions('master', ['requestApi', 'alertNotification']),

        ...mapMutations('pos', ['setPartyIdClient']),

        handlePageChange(value) {
            this.currentPage = value
            this.getClientes(this.filters)
        },

        getClientes(filters) {

            this.setLoadingTable(true)
            this.dataTable = []

            this.filters.forEach(obj => {
                if (this.currentPage != 1 && obj.v_model != '') {
                    this.currentPage = 1
                    return false;
                }
            })

            this.setUrl('lista-partys')
            this.requestApi({
                method: 'GET',
                data: {
                    page: this.currentPage,
                    itemsPerPage: this.itemsPerPage,
                    code: this.filters[0].v_model,
                    first_name: this.filters[2].v_model,
                    last_name: this.filters[3].v_model,
                    identification: this.filters[1].v_model,
                    status: this.filters[4].v_model,
                    classification: this.filters[5].v_model,
                    typeList: 'customers',
                    paginator: true
                }
            }).then(res => {
                this.totalItems = res.data.page_count
                this.dataTable = res.data._embedded.lista_partys
            }).then(() => {
                this.setLoadingTable(false)
            })
        },

        deleteClasification(clasification) {

            this.overlay = true
            this.setUrl(`lista-partys`)

            this.requestApi({
                method: 'DELETE',
                data: {
                    partyId: this.customer.party_id,
                    typeDelete: 'deleteClassification',
                    ...clasification
                }
            }).then(res => {

                let deletedClassification = this.customer.classifications.find(e => e.party_classification_group_id == clasification.party_classification_group_id)
                if (typeof deletedClassification != 'undefined') {
                    let index = this.customer.classifications.indexOf(deletedClassification)
                    this.customer.classifications.splice(index, 1)
                }
                this.alertNotification({ param: { html: res.data.detail } })

            }).catch(() => {

            }).then(() => {
                this.overlay = false

            })

        },

        editCustomer(party_id) {

            this.overlay = true
            this.setUrl(`lista-partys/${party_id}`)
            this.existPartyId = party_id != null

            this.requestApi({
                method: 'GET',
                data: {
                    typeFind: 'getCustomerData',
                    workEffortId: this.$route.params.workEffortId
                }
            }).then(res => {

                this.dialog = true
                this.partyIdCustomer = party_id
                this.successWorkrEffort = res.data.successWorkrEffort
                Object.assign(this.customer, res.data.customer)

                if (this.customer.agreements.length)
                    this.expanded[0] = this.customer.agreements[0]

                this.identificationType = res.data.customer.party_type_id == 'PARTY_GROUP' || (this.customer.party_identification_type_id == 'RUC' && this.customer.identification.charAt(2) == 9) ? 'company' : 'particular'
                this.maxIdentification = this.customer.party_identification_type_id == 'RUC' ? 13 : (this.customer.party_identification_type_id == 'CEDULA' ? 10 : 20)
                this.advisers = res.data.advisers
                this.classificationsGroups = res.data.partyClasificationGroups
                this.paymentMethods = res.data.paymentMethods
                this.reasons = res.data.reasons
                this.purposes = res.data.purposes
                this.setPartyIdClient(party_id)

            }).catch(() => {

            }).then(() => {

                this.setLoadingTable(false)
                this.overlay = false

            })

        },

        getClassification() {

            this.overlay = true
            this.setUrl(`lista-partys/0`)

            this.requestApi({
                method: 'GET',
                data: {
                    'typeFind': 'getCustomerData'
                }
            }).then(res => {

                this.filters[5].items = res.data.partyClasificationGroups
                this.contactMechAddressPurposeType = res.data.contactMechAddressPurposeType

            }).catch(() => {

            }).then(() => {

                this.setLoadingTable(false)
                this.overlay = false

            })

        },

        closeDialog() {
            this.dialog = false
            this.agreementProduct = []
            Object.assign(this.customer, this.defaultCustomer)
        },

        storeNewClassification() {

            this.overlay = true
            this.setUrl(`lista-partys`)

            this.requestApi({
                method: 'POST',
                data: {
                    typeStore: 'storeNewClassifications',
                    ...this.customer
                }
            }).then(res => {

                console.log(res)
                this.customer.newClassifications.forEach(party_classification_group_id => {

                    let cgs = this.classificationsGroups.find(e => e.party_classification_group_id == party_classification_group_id)

                    if (typeof cgs != 'undefined') {
                        this.customer.classifications.push({
                            party_classification_group_id: party_classification_group_id,
                            description: cgs.description
                        })
                    }

                })
                this.customer.newClassifications = []
                this.alertNotification({ param: { html: res.data.res.msg } })

            }).catch(() => {

            }).then(() => {

                this.setLoadingTable(false)
                this.overlay = false

            })

        },

        addContacts() {
            this.customer.contacts.unshift({
                party_id_to: '',
                party_id_from: '',
                role_type_id_from: '',
                role_type_id_to: '',
                first_name: '',
                last_name: '',
                contact_mech_id_phone: '',
                number: '',
                contact_mech_id_mail: '',
                info_string: ''
            })
        },

        deleteContact(data) {

            let index = this.customer.contacts.indexOf(data)

            if (data.party_id_from == '') {

                this.customer.contacts.splice(index, 1)

            } else {

                Vue.swal({
                    html: "Está seguro de eliminar este contacto?",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Aceptar',
                    cancelButtonText: 'Cerrar',
                    ...this.paramAlertQuestion
                }).then(result => {
                    if (result.isConfirmed) {

                        const {
                            party_id_from,
                            party_id_to,
                            role_type_id_from,
                            role_type_id_to
                        } = data

                        this.setUrl('lista-partys')
                        this.requestApi({
                            method: 'DELETE',
                            data: {
                                party_id_to,
                                party_id_from,
                                role_type_id_from,
                                role_type_id_to,
                                typeDelete: 'deleteContact'
                            }
                        }).then(res => {

                            this.alertNotification({
                                param: {
                                    html: res.data.detail
                                }
                            })

                            this.customer.contacts.splice(index, 1)

                        })

                    }
                })

            }
        },

        storeContacts() {

            if (!this.$refs.form_contacts_data.validate())
                return

            const {
                contacts
            } = this.customer

            this.setLoadingBtn(true)
            this.setUrl('lista-partys')

            this.requestApi({
                method: 'POST',
                data: {
                    new_role_type_id_to: 'CUSTOMER',
                    party_id: this.customer.party_id,
                    contacts,
                    typeStore: 'storeContacts'
                }
            }).then(res => {

                this.alertNotification({
                    param: {
                        html: res.data.res.msg
                    }
                })

            }).then(() => {
                this.setLoadingBtn(false)
            })
        },

        addAdviser() {
            this.customer.advisers.push({
                party_id: '',
                new: true
            })
        },

        storeAdviser(adviser) {

            if (!this.$refs.form_advisers_data.validate())
                return

            this.setLoadingBtn(true)
            this.setUrl('lista-partys')

            this.requestApi({
                method: 'POST',
                data: {
                    partyIdTo: this.partyIdCustomer,
                    partyIdFrom: adviser.party_id,
                    typeStore: 'storeClientAdvisor'
                }
            }).then(res => {

                this.alertNotification({
                    param: {
                        html: res.data.res.msg
                    }
                })

            }).then(() => {
                this.setLoadingBtn(false)
            })

        },

        deleteAdviser(adviser) {

            let obj = this.customer.advisers.find(e => e.party_id == adviser.party_id)

            if (adviser.new == true) {

                if (typeof obj != 'undefined') {
                    let index = this.customer.advisers.indexOf(obj)
                    this.customer.advisers.splice(index, 1)
                }

            } else {

                Vue.swal({
                    html: "Está seguro de eliminar esta asesor del cliente?",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Aceptar',
                    cancelButtonText: 'Cerrar',
                    ...this.paramAlertQuestion
                }).then(result => {

                    if (result.isConfirmed) {


                        this.setLoadingBtn(true)
                        this.setUrl('lista-partys')

                        this.requestApi({
                            method: 'DELETE',
                            data: {
                                partyIdTo: this.partyIdCustomer,
                                partyIdFrom: adviser.party_id,
                                typeDelete: 'deleteAdviser'
                            }
                        }).then(res => {

                            if (typeof obj != 'undefined') {
                                let index = this.customer.advisers.indexOf(obj)
                                this.customer.advisers.splice(index, 1)
                            }

                            this.alertNotification({
                                param: {
                                    html: res.data.detail
                                }
                            })

                        }).then(() => {
                            this.setLoadingBtn(false)
                        })

                    }

                })

            }

        },

        findCustomData() {

            this.loadingCustomSearch = true
            this.setUrl(`lista-partys/${this.searchIdentification}`)
            this.requestApi({
                method: 'GET',
                data: {
                    typeFind: 'findCustomData'
                }
            }).then(res => {

                res.data.success
                    ? Object.assign(this.customer, res.data.customer)
                    : Object.assign(this.customer, this.defaultCustomer)

                this.alertNotification({
                    param: {
                        title: res.data.success ? 'Éxito' : 'Error',
                        icon: res.data.success ? 'success' : 'error',
                        html: res.data.msg
                    }
                })

            }).catch(() => {

            }).then(() => {
                this.loadingCustomSearch = false
            })

        },

        setMinMaxIdentification() {
            this.maxIdentification = this.customer.party_identification_type_id == 'RUC' ? 13 : (this.customer.party_identification_type_id == 'CEDULA' ? 10 : 20)
            this.minIdentification = this.customer.party_identification_type_id == 'RUC' ? 13 : (this.customer.party_identification_type_id == 'CEDULA' ? 10 : 5)
        },

        storeCustomer() {

            if (!this.$refs.form_general_data.validate())
                return

            this.setUrl('lista-partys')
            this.loadingCustomStore = true
            this.requestApi({
                method: 'POST',
                data: {
                    typeStore: 'storeCustomer',
                    maxIdentification: this.maxIdentification,
                    minIdentification: this.minIdentification,
                    identificationType: this.identificationType,
                    ...this.customer
                }
            }).then(res => {

                if (this.customer.party_id == null) {
                    this.closeDialog()
                    this.editCustomer(res.data.res.partyId)
                }

                this.alertNotification({
                    param: {
                        html: res.data.res.msg
                    }
                })

            }).catch(() => {

            }).then(() => {
                this.loadingCustomStore = false
            })

        },

        searchAgreementProduct(product) {

            this.loadingAgreementSearch = true
            this.setUrl(`buscar-producto/${product}`)
            this.requestApi({
                method: 'GET',
                data: {}
            }).then(res => {

                this.agreementProducts = res.data.products

            }).catch(() => {

            }).then(() => {
                this.loadingAgreementSearch = false
            })

        },

        addAgreementProduct() {

            if (this.expanded.length && this.agreementProduct.length) {

                let index = this.customer.agreements.indexOf(this.expanded[0])

                this.agreementProduct.forEach(obj => {
                    this.customer.agreements[index].products.unshift({
                        internal_name: obj.internal_name,
                        price: obj.pvp,
                        product_id: obj.product_id,
                        pvp: obj.pvp,
                    })
                })

            } else {

                Vue.swal({
                    html: "Debe desplegar el acuerdo donde desea agregar los productos",
                    showConfirmButton: false,
                    ...this.paramAlertQuestion
                })

            }

            this.agreementProduct = []
            this.agreementProducts = []
        },

        storeProductAgreement(product) {

            this.setUrl('lista-partys')
            this.loadingProductAgreementStore = true

            this.requestApi({
                method: 'POST',
                data: {
                    typeStore: 'storeProductAgreement',
                    agreementId: this.expanded[0].agreement_id,
                    ...product
                }
            }).then(res => {

                this.customer.agreements.forEach(agreement => {
                    agreement.products.forEach(product => {
                        if (product.price == '' || product.price < 0)
                            product.price = 0
                    })
                })

                this.alertNotification({
                    param: {
                        html: res.data.res.msg
                    }
                })

            }).catch(() => {

            }).then(() => {
                this.loadingProductAgreementStore = false
            })

        },

        deleteProductAgreement(product) {

            Vue.swal({
                html: "Está seguro de eliminar el producto del acuerdo?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Aceptar',
                cancelButtonText: 'Cerrar',
                ...this.paramAlertQuestion
            }).then(result => {

                if (result.isConfirmed) {

                    this.setLoadingBtn(true)
                    this.setUrl('lista-partys')

                    this.requestApi({
                        method: 'DELETE',
                        data: {
                            typeDelete: 'deleteProductAgreement',
                            agreementId: this.expanded[0].agreement_id,
                            ...product
                        }
                    }).then(res => {

                        let index = this.customer.agreements.indexOf(this.expanded[0])
                        let index2 = this.customer.agreements[index].products.indexOf(product)
                        this.customer.agreements[index].products.splice(index2, 1)

                        this.alertNotification({
                            param: {
                                html: res.data.detail
                            }
                        })

                    }).then(() => {
                        this.setLoadingBtn(false)
                    })

                }

            })

        },

        storeNewAgreement() {

            if (!this.$refs.form_agreement_data.validate())
                return false

            this.setUrl('lista-partys')

            this.requestApi({
                method: 'POST',
                data: {
                    agreementId: this.agreementId,
                    typeStore: 'storeNewAgreement',
                    expeditionAgreement: this.expeditionAgreement,
                    expirationAgreement: this.expirationAgreement,
                    agreementDescription: this.agreementDescription,
                    partyId: this.customer.party_id
                }
            }).then(res => {

                this.alertNotification({
                    param: {
                        html: res.data.res.msg
                    }
                })

                this.closeDialogNewAgreement()
                let partyId = this.customer.party_id

                if (res.data.res.agreementId != null) {
                    this.closeDialog()
                    this.editCustomer(partyId)
                    this.tab = 3
                }

            })

        },

        closeDialogNewAgreement() {

            this.dialogNewAgreement = false
            this.editCurrentAgreement = false
            this.expeditionAgreement = null
            this.expirationAgreement = null
            this.agreementDescription = null
            this.agreementId = null
        },

        editAgreement(agreement) {
            this.dialogNewAgreement = true
            this.editCurrentAgreement = true
            this.expirationAgreement = agreement.thru_date
            this.expeditionAgreement = agreement.from_date
            this.agreementDescription = agreement.desc
            this.agreementId = agreement.agreement_id
        },

        storeCreditData() {

            if (!this.$refs.form_credit_data.validate())
                return false

            this.loadingStoreCreditData = true
            this.setUrl('lista-partys')

            this.requestApi({
                method: 'POST',
                data: {
                    typeStore: 'storeCreditData',
                    agreementId: typeof this.expanded[0] != 'undefined' ? this.expanded[0].agreement_id : null,
                    creditAmount: this.customer.creditAmount,
                    daysTerm: this.customer.daysTerm,
                    partyId: this.customer.party_id
                }
            }).then(res => {

                this.alertNotification({
                    param: {
                        html: res.data.res.msg
                    }
                });
                this.customer.aprobado = false;

            }).catch(() => {

            }).then(() => {
                this.loadingStoreCreditData = false
            })

        },

        approveCreditData() {

            if (!this.$refs.form_credit_data.validate())
                return false

            this.loadingStoreCreditData = true
            this.setUrl('lista-partys')

            this.requestApi({
                method: 'POST',
                data: {
                    typeStore: 'approveCreditData',
                    creditAmount: this.customer.creditAmount,
                    daysTerm: this.customer.daysTerm,
                    partyId: this.customer.party_id,
                    workEffortId: this.$route.params.workEffortId
                }
            }).then(res => {

                this.alertNotification({
                    param: {
                        html: res.data.res.msg
                    }
                })

            }).catch(() => {

            }).then(() => {
                this.loadingStoreCreditData = false
            })

        },

        storePreferences() {

            if (!this.$refs.form_preference.validate())
                return false

            const {
                preferences,
                party_id
            } = this.customer

            this.setLoadingBtn(true)
            this.setUrl('lista-partys')

            this.requestApi({
                method: 'POST',
                data: {
                    ...preferences,
                    party_id,
                    client: true,
                    typeStore: 'storePreferences'
                }
            }).then(res => {

                this.alertNotification({
                    param: {
                        html: res.data.res.msg
                    }
                })

            }).then(() => {
                this.setLoadingBtn(false)
            })

        },

        storeCreditCard() {

            if (!this.$refs.form_data_credit_card.validate())
                return false

            this.overlay = true
            this.setUrl('lista-party')
            this.requestApi({
                method: 'POST',
                data: {
                    typeStore: 'storeCreditCard',
                    partyId: this.customer.party_id,

                }
            }).then(res => {

                this.alertNotification({
                    param: {
                        html: res.data.res.msg
                    }
                })
                if (res.data.res.existsCCParty == null) {
                    this.creditCards.push({
                        card_type: res.data.res.cardType,
                        card_number: res.data.res.cardNumber,
                        payment_method_id: res.data.res.paymentMethodId
                    })
                }

                if (typeof this.payment.order_payment_preference_id != 'undefined') {
                    let index = this.payments.indexOf(this.payment)
                    this.payments[index].payment_method_id = res.data.res.paymentMethodId
                }
                //this.closeDialogCreditCard()
                this.overlay = false
            })

        },

        deletePaymentMethod(cc) {

            Vue.swal({
                html: "Está seguro de eliminar este método de pago del cliente?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Aceptar',
                cancelButtonText: 'Cerrar',
                ...this.paramAlertQuestion
            }).then(result => {

                if (result.isConfirmed) {

                    this.overlay = true
                    this.setUrl('lista-partys')

                    this.requestApi({
                        method: 'DELETE',
                        data: {
                            typeDelete: 'deleteCreditCard',
                            paymentMethodId: cc.payment_method_id
                        }
                    }).then(res => {

                        this.editCustomer(cc.party_id)
                        this.alertNotification({
                            param: {
                                html: res.data.detail
                            }
                        })

                    }).then(() => {
                        this.overlay = false
                    })

                }

            })

        },

        closeDialogCreditCard() {
            this.dialogCreditCard = false
            Object.assign(this.dataStoreCreditCard, this.defaultDataStoreCreditCard)
        },

        editReminder(we) {
            we.estimated_start_date = moment(we.estimated_start_date).format('YYYY-MM-DDTHH:mm')
            we.client_id = this.$route.params.partyId
            this.reminder = we
            this.dialogReminder = true
        },

        closeDialogReminder() {
            this.dialogReminder = false
            this.reminder = {}
        },

        storePhone(data) {
            if (data.res.status === 201) {
                this.customer.phones.unshift({
                    contact_mech_id: data.res.data.contactMechId,
                    contact_number: data.tlf
                })
            }
        },

        deletePhone(e, item) {

            e.stopPropagation()

            Vue.swal({
                html: "Está seguro de eliminar este número de contacto?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Aceptar',
                cancelButtonText: 'Cerrar',
                ...this.paramAlertQuestion
            }).then(result => {

                if (result.isConfirmed) {

                    this.overlay = true
                    this.setUrl('lista-partys')
                    this.requestApi({
                        method: 'DELETE',
                        data: {
                            typeDelete: 'deletePhone',
                            contact_mech_id: item.contact_mech_id,
                            party_id: this.partyIdClient

                        }
                    }).then(res => {

                        this.alertNotification({
                            param: {
                                html: res.data.detail
                            }
                        })

                        let index = this.customer.phones.indexOf(item)
                        if (index > -1)
                            this.customer.phones.splice(index, 1)

                        this.overlay = false

                    })

                }

            })

        },

        storeReminder() {

            if (!this.$refs.form_reminder.validate())
                return false

            Vue.swal({
                html: "Está seguro de completar este recordatorio?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Aceptar',
                cancelButtonText: 'Cerrar',
                ...this.paramAlertQuestion
            }).then(result => {

                if (result.isConfirmed) {

                    this.overlay = true
                    this.setUrl('lista-partys')
                    this.requestApi({
                        method: 'POST',
                        data: {
                            typeStore: 'storeReminder',
                            ...this.reminder
                        }
                    }).then(res => {

                        this.alertNotification({
                            param: {
                                html: res.data.res.msg
                            }
                        })
                        this.editCustomer(this.$route.params.partyId)
                        this.tab = 5
                        this.reminder.current_status_id = 'CAL_TAR_COMPLETADO'
                        this.overlay = false

                    })

                }

            })

        },

        getGeoData(url, input) {

            this.setUrl(`geo/${url}`)
            this.requestApi({
                method: 'GET',
                data: {}
            }).then(res => {
                if (input === 'provinces') {
                    this.provinces = res.data.detail
                } else if (input === 'states') {
                    this.states = res.data.detail
                } else {
                    this.sectors = res.data.detail
                    this.autoCompleteSector = res.data.detail.length
                }
            })

        },

        storeDirection() {

            if (!this.$refs.from_direction.validate())
                return

            this.setLoadingBtn(true)
            this.setUrl('lista-partys')

            this.requestApi({
                method: 'POST',
                data: {
                    province: this.province,
                    street: this.street,
                    street2: this.street2,
                    city: this.state.parroquia,
                    sector: this.sector,
                    reference: this.reference,
                    directionPurposes: this.directionPurposes,
                    partyId: this.customer.party_id,
                    typeStore: 'storeDirection'
                }
            }).then(res => {

                this.alertNotification({
                    param: {
                        html: res.data.res.msg
                    }
                })

                this.customer.directions.unshift({
                    state: this.state.parroquia,
                    sector: this.sector,
                    address1: this.street,
                    observaciones: this.reference,
                    reference: this.reference,
                    state_province_geo_id: this.province,
                    contact_mech_id: res.data.res.contact_mech_id,
                    party_id: this.customer.party_id,
                    party_contact_mech_purposes: this.contactMechAddressPurposeType.filter(
                        e => this.directionPurposes.includes(e.contact_mech_purpose_type_id)
                    )
                })
                this.directionPurposes = []


            }).then(() => {
                this.setLoadingBtn(false)
            })
        },

        deleteDirectionPuporse(direction, purpose) {


            let obj = this.customer.directions.find(e => e.contact_mech_id == direction.contact_mech_id)
            let index = this.customer.directions.indexOf(obj)

            let obj2 = this.customer.directions[index].party_contact_mech_purposes.find(e => e.contact_mech_purpose_type_id == purpose.contact_mech_purpose_type_id)
            let index2 = this.customer.directions[index].party_contact_mech_purposes.indexOf(obj2)

            if (direction.contact_mech_id == '') {

                this.customer.directions[index].party_contact_mech_purposes.splice(index2, 1)

            } else {

                Vue.swal({
                    html: "Está seguro de eliminar este proposito?",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Aceptar',
                    cancelButtonText: 'Cerrar',
                    ...this.paramAlertQuestion
                }).then(result => {
                    if (result.isConfirmed) {

                        const {
                            contact_mech_id,
                            party_id
                        } = direction

                        const { contact_mech_purpose_type_id } = purpose

                        this.setUrl('lista-partys')

                        this.requestApi({
                            method: 'DELETE',
                            data: {
                                contact_mech_purpose_type_id,
                                contact_mech_id,
                                party_id,
                                typeDelete: 'deletePursope'
                            }
                        }).then(res => {
                            console.log(res)

                            this.alertNotification({
                                param: {
                                    html: res.data.detail
                                }
                            })
                            this.customer.directions[index].party_contact_mech_purposes.splice(index2, 1)

                        })

                    }
                })

            }
        },

        storeOtherDirectionPursope(index) {
            console.log(this.customer.directions[index])

            const {
                contact_mech_id,
                other_purposes,
                party_contact_mech_purposes,
                party_id
            } = this.customer.directions[index]

            this.setUrl('lista-partys')
            this.requestApi({
                method: 'POST',
                data: {
                    contact_mech_id,
                    other_purposes,
                    party_contact_mech_purposes,
                    party_id,
                    typeStore: 'purposeDirection'
                }
            }).then(res => {
                console.log(res)
                this.alertNotification({
                    param: {
                        html: res.data.res.msg
                    }
                })

                other_purposes.forEach(contact_mech_purpose_type_id => {
                    this.customer.directions[index].party_contact_mech_purposes.push(
                        this.contactMechAddressPurposeType.find(e => e.contact_mech_purpose_type_id == contact_mech_purpose_type_id)
                    )
                })
                this.customer.directions[index].contact_mech_id = res.data.res.contact_mech_id
                this.customer.directions[index].other_purposes = []

            })

        },

        deleteDirection(data) {
            console.log(data)

            Vue.swal({
                html: "Está seguro de eliminar esta dirección?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Aceptar',
                cancelButtonText: 'Cerrar',
                ...this.paramAlertQuestion
            }).then(result => {
                if (result.isConfirmed) {

                    const {
                        party_id,
                        contact_mech_id
                    } = data

                    this.setUrl('lista-partys')
                    this.requestApi({
                        method: 'DELETE',
                        data: {
                            party_id,
                            contact_mech_id,
                            typeDelete: 'deleteDirection'
                        }
                    }).then(res => {
                        console.log(res)
                        this.alertNotification({
                            param: {
                                html: res.data.detail
                            }
                        })

                        let index = this.customer.directions.indexOf(data)
                        this.customer.directions.splice(index, 1)

                    })

                }
            })

        },

    },
    mounted() {

        if (this.$route.params.partyId != null) {
            this.editCustomer(this.$route.params.partyId)
            //this.tab=3
        }

        this.getClassification()
        this.setTitleToolbar('CLIENTES')
        this.getGeoData('ECU/provincias-pais', 'provinces')

    }

}
</script>

<style>
.v-dialog {
    background-color: white;
}

.v-tab {
    color: green;
    background-color: lightgray;
    font-size: 0.75rem;
}

.v-divider--inset {
    max-width: 100% !important;
    margin-left: 10px !important;
    border-color: rgb(212 212 212) !important;
}
</style>